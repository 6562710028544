import { ErrorMessage, Field, Form, Formik } from "formik";
import { PageProps } from "gatsby";
import React, { FC, useEffect, useState, useRef } from "react";
import { object, string } from "yup";
import Layout from "../components/layout/Layout";
import { navigate } from "gatsby";

export const PanelPage: FC<PageProps> = ({ location }) => {
  const [loading, setLoading] = useState(false);
  const userRef = useRef<string | null>(null);

  useEffect(() => {
    if (location.search === "") {
      navigate("/", {
        replace: true,
      });
      return;
    }

    const params = new URLSearchParams(location.search);
    const user = params.get("usr_accs");

    if (!user) {
      navigate("/", {
        replace: true,
      });
      return;
    }

    userRef.current = user;

    fetch("https://aerotec.com.mx/api/verifyUser.php", {
      method: "POST",
      body: JSON.stringify({ name: user }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (!data.exist) {
          navigate("/", {
            replace: true,
          });
          return;
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <Layout
      navbar={{ staticOpacity: "solid" }}
      footer={false}
      floatingButtons={false}
      customContainerClassName="custom-h-screen"
    >
      <div className="container pt-20">
        <h1 className="text-center font-bold text-5xl uppercase my-4">Panel</h1>
        <div className="w-full md:w-10/12 md:mx-auto rounded-lg opacity-25 h-1 bg-gray-400"></div>
        <h2 className="text-center text-2xl my-2">
          Modificar botón de Whatsapp
        </h2>
        <div className="w-10/12 mx-auto max-w-md">
          <Formik
            initialValues={{
              seller: "",
              phone: "",
              password: "",
            }}
            validationSchema={object({
              seller: string()
                .max(30, "El nombre no puede tener más de 30 carácteres")
                .required("El nombre es requerido"),
              phone: string()
                .min(10, "El número no puede tener menos de 10 dígitos")
                .max(10, "El número no puede tener más de 10 dígitos")
                .required("El telefono es requerido"),
              password: string().required("La clave de acceso es requerida"),
            })}
            onSubmit={async (values, props) => {
              try {
                const formErrors = await props.validateForm(values);
                const fields = Object.values(formErrors);
                const isValid = fields.every((field) => field.length === 0);

                if (isValid) {
                  props.setSubmitting(true);

                  const loginResponse = await fetch(
                    "https://aerotec.com.mx/api/login.php",
                    {
                      method: "POST",
                      body: JSON.stringify({
                        name: userRef.current,
                        password: values.password,
                      }),
                    }
                  );

                  const loginResponseJSON = await loginResponse.json();

                  if (loginResponseJSON.login === "success") {
                    const response = await fetch(
                      "https://aerotec.com.mx/api/whatsapp-buttonp.php",
                      {
                        method: "POST",
                        body: JSON.stringify({
                          seller: values.seller,
                          phone: values.phone,
                        }),
                      }
                    );

                    const responseJSON = await response.json();

                    if (responseJSON.wasWritten) {
                      props.resetForm();
                    } else {
                      throw new Error(
                        "Ha ocurrido un error al registrar al vendedor, por favor intente más tarde"
                      );
                    }
                  } else if (loginResponseJSON.login === "unsuccess") {
                    props.setFieldError(
                      "password",
                      "La contraseña es incorrecta"
                    );
                  } else {
                    throw new Error(
                      "Ha ocurrido un error, por favor intenta más tarde"
                    );
                  }
                }
              } catch (error) {
                console.error(error);
              }
              props.setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form className="grid grid-cols-1 gap-6 my-6">
                <Field
                  type="text"
                  name="seller"
                  placeholder="Vendedor(a)"
                  className="input-panel"
                />
                {/* @ts-ignore */}
                <ErrorMessage
                  name="seller"
                  className="text-red-700"
                  component="span"
                />
                <Field
                  type="text"
                  name="phone"
                  placeholder="Whatsapp"
                  className="input-panel"
                />
                {/* @ts-ignore */}
                <ErrorMessage
                  name="phone"
                  className="text-red-700"
                  component="span"
                />
                <Field
                  name="password"
                  type="password"
                  placeholder="Clave de acceso"
                  className="input-panel"
                />
                {/* @ts-ignore */}
                <ErrorMessage
                  name="password"
                  className="text-red-700"
                  component="span"
                />
                <button
                  type="submit"
                  className="bg-black text-white px-4 py-2 rounded-lg transition-colors duration-300 ease-out hover:bg-gray-900"
                  disabled={isSubmitting}
                >
                  Cambiar botón
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default PanelPage;
